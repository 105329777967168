import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import MainContent from "../components/mainContent"
import IgWidget from "../components/igWidget"
import ReviewsWidget from "../components/reviewsWidget"

// import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/pytco.css"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Perfectly Yours Travel Co." />
    <MainContent />
    <ReviewsWidget posts={data.allMdx} />
    <IgWidget />
    {/* <Cta /> */}
  </Layout>
)

/* Uncomment below to get the SVG Path lengths if updating the logo svg */
// let log = document.querySelectorAll("#logo path");
// for(let i = 0; i<log.length; i++){
//   console.log(i);
//   console.log(log[i].getTotalLength());
// }

export default IndexPage


export const query = graphql`
  query Reviews {
    allMdx (sort: {fields: body}, filter: {frontmatter: {key: {ne: "Team"}}}) {
      edges {
        node {
          body
          frontmatter {
            title
            date(formatString: "MM/DD/yyyy")
            subtitle
          }
          excerpt
          id
        }
      }
    }
  }
`