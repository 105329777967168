import * as React from "react"
import mp4 from "../assets/video/BackgroundTransitions.mp4"
import webm from "../assets/video/BackgroundTransitions.webm"
import poster from "../images/photos/background-video-poster.jpg"

import * as styles from '../css/logo.module.css'

 const Logo = () => {
   return (
     <div className={styles.logoContainer} id={styles.logoContainer}>
       <video autoPlay loop muted playsInline className={styles.bkgGif} poster={poster} >
         <source src={webm} />
         <source src={mp4} />
       </video>
       <svg width="507" height="506" viewBox="0 0 507 506" fill="none" xmlns="http://www.w3.org/2000/svg" id={styles.logo}>
        <title>Perfectly Yours Travel Co. Logo</title>
        <desc>A caligraphy styled outline of the globe with a plane shown flying accross it.</desc>
        <path d="M487.213 284.251C476.777 295.48 462.979 360.986 454.416 378.43C427.956 432.332 389.942 460.406 357.145 478.373C314.784 501.581 260.247 505.699 247.949 501.581C235.65 497.463 226.706 480.619 227.824 470.512C228.942 460.406 237.513 436.449 237.886 425.22C238.259 413.99 211.053 392.28 209.562 384.045C208.071 375.81 214.78 345.115 209.562 338.752C204.344 332.389 229.687 315.919 265.092 338.752C300.497 361.585 299.006 358.591 310.187 363.457C321.368 368.323 347.828 364.206 337.393 378.43C326.958 392.654 316.895 413.616 300.497 422.225C284.099 430.835 253.539 463.775 247.949 474.63C242.358 485.485 234.532 501.581 213.289 501.581C194.573 501.581 157.013 496.864 97.7565 449.925C64.5875 418.257 51.7671 403.764 31.7912 363.457C6.82129 313.073 2.34907 251.536 18.3745 224.585" stroke="#F5F5F5" strokeWidth="4" strokeLinecap="round"/>
        <path d="M309.815 9.35074C319.132 12.3453 362.736 21.329 400.005 53.5205C437.273 85.7119 432.428 102.331 448.826 113.561C465.224 124.791 465.597 112.438 457.025 103.08C448.454 93.722 415.285 82.4924 392.551 73.883C369.817 65.2737 363.109 61.2001 359.382 64.8994C346.711 77.4769 386.961 149.197 402.986 142.234C419.011 135.271 393.669 85.7119 379.88 85.7119C366.09 85.7119 371.473 138.266 372.798 149.87C374.723 166.714 383.606 175.085 386.215 177.195C391.433 181.417 410.46 199.519 413.794 207.889C419.011 220.991 410.336 234.778 402.986 230.349C401.123 229.226 386.215 214.253 391.433 207.889C396.135 202.154 421.248 196.66 421.248 196.66C421.248 196.66 448.826 191.045 457.025 198.157C468.245 207.889 471.933 232.595 471.933 232.595C471.933 232.595 478.641 278.262 490.94 264.412C501.77 252.216 505.474 228.851 499.511 222.488C488.927 211.193 460.752 219.119 448.454 222.488C436.155 225.857 420.502 229.226 408.949 240.081C397.396 250.936 372.053 287.245 386.215 313.073C396.011 330.939 403.742 334.688 413.794 332.164C425.72 329.169 440.255 321.308 441.373 332.164C442.491 343.019 436.9 364.805 433.173 378.43C429.447 392.055 401.653 437.496 415.285 430.235C439.882 417.134 471.266 363.981 481.995 336.655C490.686 314.522 498.453 272.154 487.213 284.251" stroke="#F5F5F5" strokeWidth="4" strokeLinecap="round"/>
        <path d="M2.34912 248.541C3.21872 188.525 42.5579 51.2561 179.002 31.585C256.893 20.3554 126.453 -23.0657 142.479 81.9687C146.159 106.09 44.0899 117.529 74.2774 124.267C110.055 132.252 97.3839 122.395 105.583 147.849C117.037 183.407 97.7565 213.729 97.7565 224.585C97.7565 248.69 135.025 298.475 166.331 317.416C192.118 333.018 215.152 333.512 200.618 317.416C186.083 301.32 197.263 291.588 189.437 295.705C181.611 299.823 169.685 312.175 166.331 303.94C162.976 295.705 153.287 266.134 179.002 269.129C204.717 272.123 202.854 275.492 203.972 282.23C205.09 288.967 205.09 289.716 208.071 290.09C211.053 290.465 211.053 279.235 209.562 275.118C208.071 271 202.854 265.76 223.351 251.536C243.849 237.311 269.192 221.59 260.247 212.981C251.303 204.371 274.037 201.002 271.801 187.527C269.564 174.051 242.731 135.496 228.569 137.368C214.407 139.24 235.277 185.655 218.507 185.281C201.736 184.907 195.4 168.062 189.437 152.715C183.474 137.368 210.68 117.155 218.507 109.668C226.333 102.182 224.842 88.7065 218.507 91.701C212.171 94.6956 211.053 117.155 226.333 124.267C241.613 131.379 249.812 116.181 263.601 121.047C280.131 126.88 287.081 148.194 297.516 145.004C307.951 141.814 302.361 112.064 306.088 100.46C309.213 90.7275 312.796 75.456 297.516 53.5205C282.236 31.585 263.601 36.9748 259.129 19.9811C255.551 6.38608 261.365 2.98734 264.72 2.98734C279.254 3.36165 300.497 6.35619 309.814 9.35076" stroke="#F5F5F5" strokeWidth="4" strokeLinecap="round"/>
        <path d="M10.921 152.865C-0.259516 197.034 21.4159 209.69 36.2636 218.37C61.2335 232.969 106.529 232.699 136.889 226.231C173.784 218.37 202.514 206.034 227.078 218.37C249.44 229.6 260.247 247.942 287.453 258.423C297.889 262.443 313.914 264.037 322.486 258.423" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeDasharray="8 8"/>
        <path d="M333.244 254.307C334.983 250.813 338.164 243.452 336.971 241.955C335.48 240.083 326.909 240.083 329.89 242.329C332.871 244.575 334.735 244.949 335.853 246.072C336.971 247.195 339.952 251.687 340.698 250.938C341.443 250.19 342.189 249.441 342.189 248.692C342.189 247.944 339.952 238.211 340.325 237.463C340.698 236.714 343.679 230.725 342.934 230.351C342.189 229.976 337.716 228.853 335.48 228.105C333.244 227.356 326.536 224.736 328.399 224.362C330.263 223.987 341.443 223.239 341.816 223.239C342.189 223.239 348.897 223.239 350.015 222.116C350.909 221.217 355.357 216.002 357.469 213.506C358.587 212.383 361.121 210.437 362.314 211.635C363.506 212.833 362.314 216.127 361.568 217.624C360.202 219.745 357.32 224.287 356.723 225.485C355.978 226.982 356.351 234.468 356.723 235.965C357.096 237.463 360.823 246.821 358.959 248.318C357.469 249.516 354.611 244.824 353.369 242.329C352.624 240.208 350.76 235.965 349.27 235.965C347.779 235.965 345.667 238.461 344.797 239.709" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round"/>
       </svg>

        <svg style={{marginBottom:"3rem"}} width="1000" height="272" viewBox="0 0 1000 272" fill="none" xmlns="http://www.w3.org/2000/svg" id={styles.logoText}>
          <title>Perfectly Yours Travel Co. Logo Text</title>
          <desc>Perfectly Yours written in cursive script. Travel Co. written in rigid, professional font.</desc>
          <path d="M0.83073 231.326C3.3349 231.326 9.09291 223.303 26.3669 201.021C30.3704 195.518 34.8843 189.504 40.1319 182.758C51.6479 169.487 65.9233 153.457 83.1973 134.684C99.2272 117.155 115.991 104.889 124.253 104.889C125.258 104.889 125.513 105.399 125.513 105.639C125.513 109.403 118.256 116.166 110.488 120.919C99.7216 121.924 94.2188 123.168 94.2188 126.166C94.2188 127.682 95.7181 128.671 97.7119 128.671C101.476 128.671 106.74 126.677 111.493 123.662H111.732C126.263 122.163 148.545 119.659 163.57 109.642C163.809 109.403 164.32 108.892 164.32 108.398C164.32 107.648 163.57 107.138 162.804 107.138C162.565 107.138 162.055 107.138 162.055 107.393C149.039 115.911 131.016 118.654 116.74 120.169C123.009 115.4 128.256 109.403 128.256 105.639C128.256 103.39 126.757 102.146 124.253 102.146C115.002 102.146 97.7119 114.411 81.2035 132.674C76.6896 137.443 71.9365 142.691 67.4226 147.699C80.4379 130.936 95.9733 111.397 113.997 89.1143C114.236 89.1143 114.236 88.6198 114.236 88.3646C114.236 87.615 113.487 86.8653 112.737 86.8653C112.498 86.8653 111.987 87.1205 111.732 87.3598C79.9434 126.916 55.9066 157.716 38.3933 180.253C14.1012 208.294 -4.17761 231.326 0.83073 231.326Z" fill="#F5F5F5"/>
          <path d="M165.316 129.676C173.083 129.676 197.359 122.673 214.889 109.642C215.383 109.403 215.638 108.893 215.638 108.398C215.638 107.648 214.889 107.138 214.139 107.138C213.884 107.138 213.629 107.138 213.389 107.393C196.115 119.914 172.573 126.677 165.316 126.677C159.558 126.677 156.559 125.178 156.559 121.924C156.559 118.654 159.063 114.651 162.317 111.157C163.816 111.907 165.81 112.146 168.569 112.146C173.083 112.146 178.841 108.398 178.841 105.144C178.841 102.64 177.087 101.141 174.327 101.141C166.065 101.141 153.544 113.662 153.544 121.924C153.544 126.916 158.058 129.676 165.316 129.676V129.676ZM168.569 109.403C166.815 109.403 165.571 109.148 164.566 108.893C167.82 106.149 171.329 104.139 174.327 104.139C175.587 104.139 176.082 104.395 176.082 105.144C176.082 106.644 171.329 109.403 168.569 109.403V109.403Z" fill="#F5F5F5"/>
          <path d="M211.63 155.961C226.655 155.961 270.214 127.682 295.256 109.642C295.495 109.403 295.751 108.892 295.751 108.398C295.751 107.648 295.256 107.138 294.251 107.138C293.996 107.138 293.741 107.138 293.502 107.393C268.715 125.417 225.905 152.963 211.63 152.963C208.631 152.963 207.626 151.958 207.626 149.454C207.626 142.196 220.386 129.675 232.668 117.665C243.418 106.899 254.185 96.3875 254.185 90.3743C254.185 87.3597 250.691 85.366 246.433 81.3625C255.939 71.0906 262.957 61.8236 262.957 58.3305C262.957 56.576 261.952 55.3159 260.198 55.3159C252.43 55.3159 234.917 68.5864 234.917 74.8548C234.917 77.1038 237.421 78.8583 241.919 81.8569C233.657 90.6295 223.146 100.136 213.129 107.393C212.89 107.648 212.634 108.143 212.634 108.398C212.634 109.403 213.129 109.897 213.879 109.897C214.389 109.897 214.628 109.897 214.883 109.642C223.895 103.134 235.156 93.1177 244.423 83.3563C249.431 86.8653 251.186 88.6198 251.186 90.3743C251.186 95.1275 241.424 104.889 230.658 115.4C218.392 127.682 204.867 140.936 204.867 149.454C204.867 153.457 206.876 155.961 211.63 155.961V155.961ZM243.929 79.8632C239.415 76.8486 237.915 75.3493 237.915 74.8548C237.915 70.5961 252.191 59.8298 258.954 58.5697C259.703 58.3305 260.198 58.8249 259.943 59.5746C258.204 63.8333 249.176 74.3444 243.929 79.8632Z" fill="#F5F5F5"/>
          <path d="M230.409 203.03C231.909 203.03 233.663 200.271 235.418 197.017C235.912 196.267 235.912 195.518 235.418 195.263C246.679 176.744 265.452 149.709 285.485 122.913C287.495 123.168 289.234 123.423 290.749 123.423C305.264 123.423 321.038 121.413 337.802 109.642C338.312 109.403 338.567 108.892 338.567 108.398C338.567 107.648 337.802 107.138 337.052 107.138C336.813 107.138 336.302 107.138 336.063 107.393C320.033 118.654 305.264 120.409 290.749 120.409H287.495C290.238 116.66 292.998 112.896 295.741 109.403C326.286 92.3681 365.348 48.0586 365.348 39.0468C365.348 37.053 363.593 35.5378 361.344 35.5378C352.827 35.5378 323.542 68.8417 294.003 107.138C293.748 107.138 293.748 107.138 293.748 107.393C290.494 111.397 287.24 115.655 284.225 119.914C278.467 119.42 271.21 117.904 263.697 115.4C263.458 115.161 263.458 115.161 263.203 115.161C262.453 115.161 261.704 115.911 261.704 116.66C261.704 117.41 262.198 117.904 262.709 117.904C269.711 120.409 276.474 121.924 282.232 122.673C260.444 151.463 240.426 180.748 229.899 199.027L229.149 200.526C228.91 200.526 228.91 200.781 228.91 200.781C228.655 202.025 228.91 203.03 230.409 203.03H230.409ZM300.51 103.134C328.04 68.092 353.832 38.5524 361.344 38.5524C362.094 38.5524 362.349 38.7916 362.349 39.0468C362.349 46.3041 329.54 84.3612 300.51 103.134V103.134Z" fill="#F5F5F5"/>
          <path d="M339.812 129.676C347.579 129.676 371.856 122.673 389.385 109.642C389.879 109.403 390.134 108.893 390.134 108.398C390.134 107.648 389.385 107.138 388.635 107.138C388.38 107.138 388.125 107.138 387.885 107.393C370.611 119.914 347.069 126.677 339.812 126.677C334.054 126.677 331.055 125.178 331.055 121.924C331.055 118.654 333.559 114.651 336.813 111.157C338.312 111.907 340.306 112.146 343.065 112.146C347.579 112.146 353.337 108.398 353.337 105.144C353.337 102.64 351.583 101.141 348.824 101.141C340.561 101.141 328.04 113.662 328.04 121.924C328.04 126.916 332.554 129.676 339.812 129.676V129.676ZM343.065 109.403C341.311 109.403 340.067 109.148 339.062 108.893C342.316 106.149 345.825 104.139 348.824 104.139C350.084 104.139 350.578 104.395 350.578 105.144C350.578 106.644 345.825 109.403 343.065 109.403V109.403Z" fill="#F5F5F5"/>
          <path d="M389.635 130.17C403.145 130.17 425.682 121.669 443.706 109.642C444.216 109.403 444.455 108.893 444.455 108.398C444.455 107.648 443.706 107.138 442.956 107.138C442.701 107.138 442.462 107.138 442.206 107.393C424.438 118.909 402.65 127.171 389.635 127.171C384.116 127.171 382.122 126.167 382.122 122.673V120.664C383.622 113.662 393.638 104.139 399.891 104.139C400.896 104.139 401.645 104.634 401.645 105.399C401.406 107.138 399.396 109.642 397.387 110.902C397.147 111.157 396.637 111.652 396.637 112.146C396.637 112.896 397.387 113.662 398.136 113.662C398.391 113.662 398.647 113.406 398.902 113.406C401.9 111.652 404.405 107.904 404.405 104.889C404.405 102.64 402.65 101.141 399.891 101.141C392.378 101.141 380.862 111.907 379.363 120.169C379.124 121.158 379.124 121.924 379.124 122.673C379.124 127.921 382.617 130.17 389.635 130.17V130.17Z" fill="#F5F5F5"/>
          <path d="M384.622 192.519C385.882 192.519 387.381 190.509 388.626 188.516C389.136 187.766 389.136 187 388.626 186.761C412.407 149.454 442.713 110.392 473.752 74.1052C495.523 73.1004 517.806 73.1004 539.099 73.85C540.088 73.85 540.599 73.3396 540.599 72.59C540.599 71.8403 540.088 71.0906 539.339 71.0906C518.571 70.341 497.278 70.341 476.511 71.0906C490.021 55.3159 503.546 40.0517 517.056 25.537C517.311 25.2818 517.311 24.7714 517.311 24.5322C517.311 23.7825 516.817 23.0329 515.812 23.0329C515.557 23.0329 515.062 23.2721 514.807 23.5273C500.787 38.7916 486.512 54.8215 472.491 71.3458C449.97 72.0955 428.182 73.85 408.659 76.3542C407.909 76.6094 407.399 77.1038 407.399 77.8535C407.399 78.6032 408.165 79.3528 408.914 79.3528H409.153C427.688 76.8486 448.471 75.0941 469.987 74.1052C438.454 111.157 408.165 150.459 384.128 188.26C383.872 188.755 383.617 189.265 383.378 189.76L383.123 190.015C382.628 191.514 382.868 192.519 384.622 192.519V192.519Z" fill="#F5F5F5"/>
          <path d="M476.251 139.947C486.012 139.947 514.308 124.412 535.585 109.642C535.841 109.403 536.096 109.148 536.096 108.653C536.096 107.904 535.585 107.138 534.836 107.138C534.341 107.138 534.086 107.138 533.831 107.393C510.049 123.918 484.258 137.188 476.251 137.188C471.992 137.188 470.748 136.183 470.748 132.674C470.748 127.682 474.002 119.914 479.76 110.902C522.315 85.1108 596.675 20.0183 596.675 5.50365C596.675 2.24981 594.665 0.4953 591.172 0.4953C570.133 0.4953 467.989 103.134 467.989 132.674C467.989 137.682 470.493 139.947 476.251 139.947V139.947ZM483.508 105.399C511.054 64.8382 575.636 3.25468 591.172 3.25468C593.166 3.25468 593.915 4.00433 593.915 5.50365C593.915 17.5141 527.834 76.8486 483.508 105.399V105.399Z" fill="#F5F5F5"/>
          <path d="M444.203 231.326C460.982 231.326 507.796 193.014 540.095 157.461C543.843 153.218 547.607 148.959 550.861 144.445C574.148 130.68 594.166 117.155 605.187 109.897C605.443 109.642 605.682 109.148 605.682 108.653C605.682 107.904 604.932 107.393 604.183 107.393C603.927 107.393 603.688 107.393 603.433 107.648C591.662 115.911 576.142 126.166 555.12 138.432C566.875 122.163 574.148 107.138 574.148 103.134C574.148 101.141 573.144 99.8806 571.134 99.8806C569.379 99.8806 566.126 102.146 555.87 109.642C545.358 117.665 532.327 127.427 527.08 127.427C525.82 127.427 525.58 127.171 525.58 126.422C525.58 122.673 536.331 108.143 543.843 101.635C544.098 101.38 544.354 101.141 544.354 100.63C544.354 99.8806 543.604 99.131 542.854 99.131C542.599 99.131 542.344 99.3862 542.089 99.3862C535.342 105.144 522.566 120.919 522.566 126.422C522.566 128.926 524.32 130.425 527.08 130.425C533.332 130.425 547.097 119.914 557.863 112.146C564.371 107.138 567.88 104.395 569.89 103.39C570.639 102.895 571.134 103.39 571.134 104.139C569.379 111.907 556.874 131.685 548.852 142.196L547.097 143.201L508.306 166.472C460.233 196.267 438.205 214.291 438.205 225.568C438.205 229.061 440.71 231.326 444.203 231.326V231.326ZM444.203 228.311C442.209 228.311 441.204 227.322 441.204 225.568C441.204 215.806 463.231 197.767 509.806 168.977C521.066 161.975 532.838 155.212 544.098 148.449L538.085 155.467C503.298 194.018 458.972 228.311 444.203 228.311Z" fill="#F5F5F5"/>
          <path d="M569.129 231.326C585.908 231.326 632.722 193.014 665.021 157.461C668.769 153.218 672.534 148.959 675.788 144.445C699.075 130.68 719.092 117.155 730.114 109.897C730.369 109.642 730.608 109.148 730.608 108.653C730.608 107.904 729.859 107.393 729.109 107.393C728.854 107.393 728.614 107.393 728.359 107.648C716.588 115.911 701.068 126.166 680.046 138.432C691.801 122.163 699.075 107.138 699.075 103.134C699.075 101.141 698.07 99.8806 696.06 99.8806C694.306 99.8806 691.052 102.146 680.796 109.642C670.285 117.665 657.253 127.427 652.006 127.427C650.746 127.427 650.507 127.171 650.507 126.422C650.507 122.673 661.257 108.143 668.769 101.635C669.025 101.38 669.28 101.141 669.28 100.63C669.28 99.8806 668.53 99.131 667.781 99.131C667.525 99.131 667.27 99.3862 667.015 99.3862C660.268 105.144 647.492 120.919 647.492 126.422C647.492 128.926 649.246 130.425 652.006 130.425C658.258 130.425 672.023 119.914 682.79 112.146C689.297 107.138 692.806 104.395 694.816 103.39C695.566 102.895 696.06 103.39 696.06 104.139C694.306 111.907 681.801 131.685 673.778 142.196L672.023 143.201L633.233 166.472C585.159 196.267 563.132 214.291 563.132 225.568C563.132 229.061 565.636 231.326 569.129 231.326V231.326ZM569.129 228.311C567.135 228.311 566.13 227.322 566.13 225.568C566.13 215.806 588.157 197.767 634.732 168.977C645.993 161.975 657.764 155.212 669.025 148.449L663.011 155.467C628.224 194.018 583.899 228.311 569.129 228.311Z" fill="#F5F5F5"/>
          <path d="M722.354 130.936C730.361 130.936 738.128 117.41 736.613 107.648C736.613 107.138 736.868 106.644 737.379 107.138C751.893 116.166 771.177 117.155 784.192 109.642C784.687 109.403 785.197 108.893 785.197 108.398C785.197 107.648 784.431 107.138 783.682 107.138H782.932C770.922 114.156 753.393 113.151 739.883 105.144C739.372 104.889 739.372 104.634 739.883 104.395C740.122 104.139 740.632 104.139 740.872 103.884C741.621 103.884 741.877 103.39 741.877 102.64C741.877 101.89 741.382 101.141 740.632 101.141C740.377 101.141 740.122 101.141 740.122 101.38C738.878 101.635 737.618 102.146 736.613 102.64C736.119 102.895 736.119 102.895 735.624 102.64C734.619 102.385 733.614 103.135 733.12 104.139C733.12 104.395 732.865 104.395 732.865 104.634C724.108 109.897 717.6 118.16 717.6 125.178C717.6 128.671 719.339 130.936 722.354 130.936V130.936ZM722.354 128.176C721.093 128.176 720.344 127.171 720.344 125.178C720.344 119.42 726.102 112.146 733.87 107.393C735.114 115.4 728.367 128.176 722.354 128.176V128.176Z" fill="#F5F5F5"/>
          <path d="M807.469 128.926C816.226 128.926 840.518 118.16 853.278 109.403C853.533 109.148 853.788 108.892 853.788 108.398C853.788 107.648 853.278 106.899 852.528 106.899C852.034 106.899 851.778 106.899 851.539 107.138C838.508 116.166 815.237 126.166 807.469 126.166C802.971 126.166 801.217 124.667 801.217 121.413C801.217 116.66 806.719 110.392 810.468 106.149C813.227 102.895 815.476 99.6414 811.983 99.6414C810.468 99.6414 807.214 102.385 799.207 108.892C786.447 119.164 779.173 124.173 776.175 124.173C775.68 124.173 775.425 123.662 775.425 122.913C775.425 119.659 780.433 112.896 788.951 105.399C789.19 105.144 789.445 104.889 789.445 104.395C789.445 103.645 788.696 102.895 787.946 102.895C787.436 102.895 787.196 103.134 786.941 103.39C777.674 111.652 772.427 118.654 772.427 123.168C772.427 125.417 773.926 126.916 776.175 126.916C780.178 126.916 787.436 122.163 800.961 111.157C803.96 108.892 803.721 109.403 801.966 111.907C799.956 115.161 798.457 118.415 798.457 121.413C798.457 126.422 801.456 128.926 807.469 128.926Z" fill="#F5F5F5"/>
          <path d="M850.03 155.961C865.055 155.961 908.615 127.682 933.657 109.642C933.896 109.403 934.151 108.892 934.151 108.398C934.151 107.648 933.657 107.138 932.652 107.138C932.397 107.138 932.142 107.138 931.902 107.393C907.116 125.417 864.306 152.963 850.03 152.963C847.032 152.963 846.027 151.958 846.027 149.454C846.027 142.196 858.787 129.675 871.069 117.665C881.819 106.899 892.585 96.3875 892.585 90.3743C892.585 87.3597 889.092 85.366 884.833 81.3625C894.34 71.0906 901.358 61.8236 901.358 58.3305C901.358 56.576 900.353 55.3159 898.598 55.3159C890.831 55.3159 873.317 68.5864 873.317 74.8548C873.317 77.1038 875.822 78.8583 880.32 81.8569C872.057 90.6295 861.546 100.136 851.53 107.393C851.29 107.648 851.035 108.143 851.035 108.398C851.035 109.403 851.53 109.897 852.279 109.897C852.79 109.897 853.029 109.897 853.284 109.642C862.296 103.134 873.557 93.1177 882.824 83.3563C887.832 86.8653 889.587 88.6198 889.587 90.3743C889.587 95.1275 879.825 104.889 869.059 115.4C856.793 127.682 843.267 140.936 843.267 149.454C843.267 153.457 845.277 155.961 850.03 155.961V155.961ZM882.329 79.8632C877.815 76.8486 876.316 75.3493 876.316 74.8548C876.316 70.5961 890.591 59.8298 897.354 58.5697C898.104 58.3305 898.598 58.8249 898.343 59.5746C896.605 63.8333 887.577 74.3444 882.329 79.8632Z" fill="#F5F5F5"/>
          <path d="M926.135 140.936C934.397 140.936 945.163 132.18 950.172 124.922L950.921 124.412C957.429 122.418 965.436 119.914 973.953 117.41C982.471 114.906 991.483 112.402 998.995 109.897C999.745 109.642 1000 109.148 1000 108.398C1000 107.648 999.49 107.138 998.74 107.138H998.245C990.733 109.642 981.721 112.146 973.204 114.65C966.202 116.66 959.184 118.909 953.17 120.664C952.931 120.919 952.676 120.664 952.676 120.169C952.931 119.659 952.931 119.164 953.17 118.909C953.17 116.405 951.432 115.4 948.417 115.4C946.423 115.4 943.919 115.911 941.16 116.405C938.401 116.915 935.402 117.41 933.153 117.41C930.888 117.41 930.138 116.915 930.138 116.166C930.138 110.392 943.17 100.391 950.666 100.391C951.926 100.391 952.181 100.886 952.181 101.635C952.181 103.39 949.917 106.149 948.162 107.138C947.668 107.393 947.412 107.904 947.412 108.398C947.412 109.403 948.162 109.897 948.928 109.897C949.167 109.897 949.422 109.897 949.677 109.642C951.926 108.398 954.175 105.399 954.925 102.385C955.18 102.146 955.18 101.635 955.18 101.38C955.18 99.131 953.426 97.6317 950.666 97.6317C941.654 97.6317 927.14 108.892 927.14 116.166C927.14 118.909 929.389 120.169 933.153 120.169C935.657 120.169 938.656 119.659 941.654 119.164C943.664 118.909 949.917 117.904 950.172 118.654C950.666 119.42 949.677 120.664 948.928 121.924C948.928 122.163 948.672 122.163 948.417 122.163C938.401 125.927 921.876 132.929 921.876 137.683C921.876 139.947 923.391 140.936 926.135 140.936V140.936ZM926.135 137.938C924.891 137.938 924.636 137.938 924.636 137.443C924.636 136.183 933.392 131.175 943.919 126.916C944.669 126.916 944.908 127.171 944.414 127.682C939.405 132.929 931.893 137.938 926.135 137.938V137.938Z" fill="#F5F5F5"/>
          <path d="M313.236 246.506C313.746 246.506 314.145 246.666 314.448 246.953C314.783 247.256 314.959 247.639 314.959 248.101C314.959 248.612 314.783 249.027 314.448 249.314C314.145 249.617 313.746 249.76 313.236 249.76H306.92V269.73C306.92 270.208 306.728 270.607 306.346 270.942C305.995 271.293 305.58 271.452 305.07 271.452C304.559 271.452 304.128 271.293 303.794 270.942C303.443 270.607 303.283 270.208 303.283 269.73V249.76H296.967C296.456 249.76 296.026 249.617 295.691 249.314C295.388 249.027 295.244 248.612 295.244 248.101C295.244 247.639 295.388 247.256 295.691 246.953C296.026 246.666 296.456 246.506 296.967 246.506H313.236Z" fill="#F5F5F5"/>
          <path d="M366.537 268.581C366.792 268.757 366.984 268.964 367.111 269.219C367.271 269.475 367.367 269.73 367.367 269.985C367.367 270.495 367.191 270.878 366.856 271.133C366.553 271.357 366.17 271.452 365.708 271.452C365.229 271.452 364.846 271.373 364.559 271.197C363.826 270.783 363.235 270.113 362.773 269.219C362.342 268.326 362.135 266.986 362.135 265.2C362.135 264.02 361.768 263.142 361.05 262.584C360.317 261.994 359.407 261.691 358.307 261.691H351.097V269.73C351.097 270.24 350.938 270.671 350.651 271.006C350.348 271.309 349.981 271.452 349.566 271.452C349.008 271.452 348.513 271.293 348.099 270.942C347.716 270.607 347.525 270.208 347.525 269.73V248.229C347.525 247.719 347.684 247.32 348.035 247.017C348.37 246.682 348.769 246.506 349.247 246.506H359.264C360.54 246.506 361.704 246.825 362.773 247.463C363.873 248.07 364.751 248.915 365.389 250.015C366.027 251.132 366.346 252.376 366.346 253.78C366.346 255.104 365.979 256.332 365.261 257.48C364.527 258.629 363.618 259.506 362.518 260.096C363.411 260.527 364.113 261.149 364.623 261.946C365.133 262.76 365.405 263.701 365.453 264.753C365.532 266.125 365.644 267.05 365.772 267.561C365.899 268.039 366.154 268.374 366.537 268.581ZM359.2 258.756C359.79 258.724 360.348 258.485 360.859 258.054C361.401 257.64 361.848 257.065 362.199 256.332C362.534 255.614 362.709 254.785 362.709 253.843C362.709 252.743 362.342 251.786 361.624 250.972C360.939 250.175 360.109 249.76 359.136 249.76H351.097V258.756H359.2Z" fill="#F5F5F5"/>
          <path d="M419.467 269.219C419.547 269.443 419.595 269.666 419.595 269.921C419.595 270.4 419.419 270.799 419.085 271.133C418.781 271.437 418.415 271.58 418 271.58C417.649 271.58 417.33 271.468 417.043 271.261C416.788 271.054 416.564 270.783 416.405 270.432L414.172 265.2H403.198L401.029 270.432C400.901 270.814 400.678 271.118 400.391 271.325C400.136 271.484 399.833 271.58 399.498 271.58C398.987 271.58 398.605 271.452 398.349 271.197C398.094 270.91 397.967 270.543 397.967 270.113C397.967 270.033 397.982 269.841 398.03 269.538L407.154 247.463C407.313 247.081 407.553 246.809 407.856 246.634C408.143 246.427 408.494 246.347 408.876 246.379C409.211 246.379 409.514 246.49 409.77 246.698C410.057 246.873 410.28 247.128 410.408 247.463L419.467 269.219ZM404.538 261.946H412.832L408.685 251.929L404.538 261.946Z" fill="#F5F5F5"/>
          <path d="M469.831 246.443C470.294 246.443 470.693 246.618 471.043 246.953C471.378 247.256 471.554 247.623 471.554 248.038C471.554 248.293 471.506 248.58 471.426 248.867L462.303 270.368C462.127 270.719 461.888 270.99 461.601 271.197C461.298 271.373 460.979 271.453 460.644 271.453C460.293 271.421 460.006 271.309 459.751 271.134C459.496 270.974 459.272 270.719 459.113 270.368L449.989 248.867C449.894 248.66 449.862 248.405 449.862 248.102C449.862 247.591 450.021 247.192 450.372 246.889C450.755 246.602 451.154 246.443 451.584 246.443C452.254 246.443 452.765 246.81 453.115 247.527L460.899 265.902L468.236 247.527C468.396 247.192 468.619 246.937 468.874 246.762C469.161 246.554 469.48 246.443 469.831 246.443Z" fill="#F5F5F5"/>
          <path d="M518.265 268.199C518.776 268.199 519.175 268.374 519.478 268.709C519.813 269.012 519.988 269.395 519.988 269.857C519.988 270.336 519.813 270.719 519.478 271.006C519.175 271.309 518.776 271.452 518.265 271.452H505.824C505.346 271.452 504.947 271.293 504.612 270.942C504.261 270.607 504.102 270.208 504.102 269.73V248.229C504.102 247.719 504.261 247.32 504.612 247.017C504.947 246.682 505.346 246.506 505.824 246.506H518.265C518.776 246.506 519.175 246.666 519.478 246.953C519.813 247.256 519.988 247.655 519.988 248.165C519.988 248.644 519.813 249.027 519.478 249.314C519.175 249.617 518.776 249.76 518.265 249.76H507.675V257.034H516.67C517.133 257.034 517.516 257.209 517.819 257.544C518.154 257.847 518.329 258.23 518.329 258.692C518.329 259.203 518.154 259.617 517.819 259.905C517.516 260.16 517.133 260.287 516.67 260.287H507.675V268.199H518.265Z" fill="#F5F5F5"/>
          <path d="M566.702 268.135C567.212 268.135 567.611 268.31 567.914 268.645C568.249 268.948 568.424 269.331 568.424 269.794C568.424 270.272 568.249 270.671 567.914 271.006C567.611 271.309 567.212 271.452 566.702 271.452H554.643C554.133 271.452 553.702 271.293 553.367 270.942C553.064 270.607 552.921 270.208 552.921 269.73V248.229C552.921 247.719 553.08 247.32 553.431 247.017C553.766 246.682 554.197 246.506 554.707 246.506C555.218 246.506 555.632 246.682 555.983 247.017C556.318 247.32 556.494 247.719 556.494 248.229V268.135H566.702Z" fill="#F5F5F5"/>
          <path d="M657.205 247.974C657.796 248.277 658.099 248.74 658.099 249.378C658.099 249.808 657.939 250.191 657.652 250.526C657.349 250.909 656.966 251.1 656.504 251.1C656.153 251.1 655.834 251.02 655.547 250.845C654.047 250.047 652.452 249.633 650.762 249.633C648.975 249.633 647.38 250.047 645.977 250.845C644.605 251.611 643.552 252.695 642.787 254.099C642.053 255.502 641.702 257.129 641.702 258.948C641.702 260.957 642.085 262.68 642.85 264.115C643.648 265.519 644.732 266.604 646.104 267.369C647.46 268.103 649.007 268.454 650.762 268.454C652.5 268.454 654.095 268.039 655.547 267.178C655.802 267.05 656.089 266.986 656.44 266.986C656.95 266.986 657.365 267.178 657.716 267.561C657.971 267.912 658.099 268.294 658.099 268.709C658.099 269.012 658.003 269.283 657.844 269.538C657.716 269.762 657.525 269.953 657.269 270.113C656.328 270.623 655.291 271.054 654.143 271.389C652.995 271.676 651.862 271.835 650.762 271.835C648.417 271.835 646.264 271.325 644.318 270.304C642.404 269.283 640.873 267.8 639.724 265.838C638.576 263.892 638.002 261.595 638.002 258.948C638.002 256.571 638.544 254.418 639.66 252.504C640.761 250.558 642.276 249.027 644.19 247.91C646.136 246.81 648.337 246.251 650.762 246.251C653.058 246.251 655.196 246.825 657.205 247.974Z" fill="#F5F5F5"/>
          <path d="M713.36 258.948C713.36 261.372 712.818 263.573 711.765 265.519C710.745 267.433 709.309 268.964 707.491 270.113C705.656 271.261 703.599 271.835 701.302 271.835C699.005 271.835 696.932 271.261 695.113 270.113C693.279 268.964 691.828 267.433 690.775 265.519C689.754 263.573 689.244 261.372 689.244 258.948C689.244 256.571 689.754 254.402 690.775 252.44C691.828 250.494 693.279 248.963 695.113 247.846C696.932 246.698 699.005 246.124 701.302 246.124C703.599 246.124 705.656 246.698 707.491 247.846C709.309 248.963 710.745 250.494 711.765 252.44C712.818 254.402 713.36 256.571 713.36 258.948ZM709.66 258.948C709.66 257.161 709.293 255.566 708.575 254.163C707.889 252.727 706.917 251.595 705.641 250.781C704.365 249.936 702.913 249.505 701.302 249.505C699.675 249.505 698.24 249.92 696.964 250.717C695.688 251.531 694.683 252.663 693.965 254.099C693.279 255.55 692.944 257.161 692.944 258.948C692.944 260.734 693.279 262.361 693.965 263.796C694.683 265.248 695.688 266.38 696.964 267.178C698.24 267.991 699.675 268.39 701.302 268.39C702.913 268.39 704.365 267.991 705.641 267.178C706.917 266.38 707.889 265.248 708.575 263.796C709.293 262.361 709.66 260.734 709.66 258.948Z" fill="#F5F5F5"/>
          <path d="M747.138 271.389C746.532 271.389 746.085 271.245 745.798 270.942C745.495 270.655 745.351 270.208 745.351 269.602V268.9C745.351 268.358 745.495 267.927 745.798 267.624C746.085 267.289 746.532 267.114 747.138 267.114H747.457C748.047 267.114 748.493 267.289 748.796 267.624C749.084 267.927 749.243 268.358 749.243 268.9V269.602C749.243 270.798 748.637 271.389 747.457 271.389H747.138Z" fill="#F5F5F5"/>
        </svg>
     </div>
   )
 }
 
 export default Logo
 