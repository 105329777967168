import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { StaticImage } from "gatsby-plugin-image";
import { useViewport } from "../contexts/viewportProvider";

import * as styles from "../css/reviewsWidget.module.css"


const ReviewsWidget = ({ posts }) => {
    const { width } = useViewport();
    const breakpoint = 700;

    return (
        <div className={styles.container}>
            <h2 className={`${styles.header} subheader`}>Client Testimonials</h2>
            <Carousel
                className={styles.carousel}
                controls 
                indicators 
                showThumbs={false}
                infiniteLoop
                showStatus={false}
                showIndicators={width < breakpoint ?? false}
                swipeable
                autoPlay
                interval={10000}
                renderIndicator={() => {
                    return (
                          <StaticImage className={styles.carouselIndicator} src="../images/pyt-carousel-indicator.png" alt="Indicator" />
                    )
                }}
                renderArrowPrev={(clickHandler) => {
                    return (
                        <div onClick={clickHandler} onKeyDown={(k) => k.key === "ArrowLeft" ? clickHandler : null} role="button" tabIndex={0}>
                          <StaticImage className={styles.carouselBtn} src="../images/LeftBtn.png" alt="Previous" />
                        </div>
                    );
                  }}
                renderArrowNext={(clickHandler) => {
                    return (
                        <div onClick={clickHandler} onKeyDown={(k) => k.key === "ArrowRight" ? clickHandler : null} role="button" tabIndex={0}>
                            <StaticImage className={styles.carouselBtn} src="../images/RightBtn.png" alt="Previous" />
                        </div>
                    );
                }}
                >
                {posts.edges.map(
                    item => item.node.frontmatter && (
                        <div className={styles.carouselItem} key={item.node.id}>
                            <p className={styles.text}>{item.node.body}</p>
                            <h4 className={`${styles.titleText} subheading`}>- {item.node.frontmatter.title}</h4>
                            <h6 className={`${styles.titleText} subheading`}>{item.node.frontmatter.subtitle}</h6>
                        </div>
                    )
                )}
            </Carousel>
        </div>
    );
  }

export default ReviewsWidget