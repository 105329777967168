// extracted by mini-css-extract-plugin
export var alternateSection = "index-module--alternateSection--b8017";
export var image = "index-module--image--96c15";
export var imageContainer = "index-module--imageContainer--54132";
export var intro = "index-module--intro--bf326";
export var list = "index-module--list--76fd1";
export var listItem = "index-module--listItem--95f6c";
export var listItemDescription = "index-module--listItemDescription--4685b";
export var listItemLink = "index-module--listItemLink--9b7d9";
export var logoText = "index-module--logoText--133c8";
export var mainContentSection = "index-module--mainContentSection--46c3c";
export var mainContentText = "index-module--mainContentText--f41a0";
export var perks = "index-module--perks--48a1c";
export var perksGrouping = "index-module--perksGrouping--707df";
export var perksInfo = "index-module--perksInfo--6a040";
export var perksSubgroup = "index-module--perksSubgroup--d2884";
export var sectionBreak = "index-module--sectionBreak--16fd4";
export var subGroup = "index-module--subGroup--274f4";
export var textCenter = "index-module--textCenter--009bd";