import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Logo from "../components/logo"
import Cta from "./cta"
import * as styles from "../css/index.module.css"

const MainContent = () => (
    <>
        <div className={styles.textCenter}>
            <Logo />
        </div>
        <div id="sectionA" className={styles.mainContentSection}>
            <div className={styles.subGroup}>
                    <h2 className={styles.textCenter}>A VACATION THAT IS</h2>
                    <h3 className={styles.logoText}>perfectly yours</h3>
                    <p className={styles.mainContentText}>
                        Perfectly Yours Travel Co is a full service luxury boutique travel agency whose goal is to create a memorable and personalized itinerary to make our traveler’s wanderlist a reality. We strive to make a difference, not only in your travel planning, but in your traveler’s lives. 
                    </p>
            </div>
        </div>
        <div className={`${styles.mainContentSection} ${styles.sectionBreak}`}>
            <div className={styles.mainContentText}>
                <h3 className={styles.textCenter}>WHY BOOK WITH US?</h3>
                <p className={styles.textCenter}>We take the time to get to know our travelers in order to create experiences that exceed expectations. Every trip is personally crafted for the client traveling. Our passion for travel is unmatched, and we treat every trip as if it were our own! We're here to take the stress out of planning, and ensure you get the most out of your time and money.</p>
                <div className={styles.perksGrouping}>
                    <h3 className={styles.logoText}>perfectly yours perks</h3>
                    <div className={styles.perksSubgroup}>
                        <div className={styles.perksInfo}>
                            <h2 className="subheading">We get additional benefits at select hotels around the world:</h2>
                            <ul>
                                <li>breakfast daily for 2</li>
                                <li>$100 credit</li>
                                <li>upgrades (based on availability)</li>
                                <li>VIP status</li>
                                <li>early check in / late check out</li>
                                <li>and more!</li>
                            </ul>
                        </div>
                        <div className={styles.image}>
                            <StaticImage imgStyle={{objectFit:'cover', width:'350px', height: '350px', margin:'auto'}} 
                                    src="../images/photos/greece-for-two.png" 
                                    alt="PYT Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="sectionD" className={`${styles.mainContentSection} ${styles.alternateSection}`}>
            <Cta />
        </div>
    </>
)


export default MainContent
